/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import Badge from "react-bootstrap/Badge";
import {money} from "../../lib/money/money";
import BootstrapTable from "react-bootstrap-table-next";
import NbioApi from "../../lib/api/NbioApi";
import Dropdown from "react-bootstrap/Dropdown";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert2";
import PropTypes from "prop-types";

//components
import SortBtns from "../ui/SortBtns";
import NPPaginator from "../ui/NPPaginator";
import NPIf from "np-if";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";



//const
const dayjs = require('dayjs')
const utc   = require('dayjs/plugin/utc');
dayjs.extend(utc);


class IdComponent extends  React.Component{
    constructor(props) {
        super(props);
        this.state ={
            showHandler:false
        }

        this.addToRoute = this.addToRoute.bind(this);
        this.createRoute = this.createRoute.bind(this);
    }

    addToRoute = (routeId) => {
        NbioApi.routes.addOrders(routeId,[this.props._id]).then((res) =>{
            Swal.fire({
                title: `Orden agregada a la ruta ${res.data.route.name}`,
                icon: 'success',
                confirmButtonColor: '#4dda85',
            });
            this.props.onRouteCreated();
        }).catch((ex) =>{
            let errorMsg = 'Hubo un error al agregar a la ruta';
            try {
                errorMsg = ex.response.data.error_es;
            } catch (ex) {

            }
            Swal.fire({
                title: errorMsg,
                icon: 'danger',
            });
        })
    }

    createRoute = () => {
        Swal.fire({
            title: 'Crear nueva ruta',
            text: 'Escribe el nombre de la ruta',
            input: 'text',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Crear ruta',
            confirmButtonColor: '#4dda85',
            preConfirm: (name) => {
                const route = {name:name,orders:[this.props._id]}
                if(name.trim() !== ''){
                    return  NbioApi.routes.create(route).then((res) =>{
                        return res.data;
                    }).catch((ex) =>{
                        let errorMsg = 'Hubo un error al crear a la ruta';
                        try {
                            errorMsg = ex.response.data.error_es;
                        } catch (ex) {

                        }
                        Swal.fire({
                            title: errorMsg,
                            icon: 'danger',
                        });
                        throw(ex);
                    })
                }
            }
        }).then((result) => {
            if (result.isConfirmed) {
                this.props.onRouteCreated();
                Swal.fire({
                    title: `Ruta ${result.value.route.name} creada`,
                    icon: 'success',
                    confirmButtonColor: '#4dda85',
                });
            } else if(! result.isDismissed) {
                Swal.fire({
                    title: `Hubo un error al crear la ruta`,
                    icon: 'danger',
                });
            }
        }).catch((ex) =>{

        })
    }

    render(){
        const order = this.props.order;
        const route = order.route || {};
        return (
            <div className={'d-flex '}
                 onMouseEnter={() => {
                     this.setState({showHandler: true})
                 }}
                 onMouseLeave={() => {
                     this.setState({showHandler: false})
                 }}
            >
                <div className={'d-flex'}>
                    <NPIf condition={this.props.order.route}>
                            <OverlayTrigger
                                placement={'top'}
                                overlay={
                                    <Tooltip id={`tooltip-${this.props.order._id}`}>
                                        En ruta {route.name}.
                                    </Tooltip>
                                }
                            >
                                <div><FontAwesomeIcon icon={"route"} style={{color: "#26bee7"}}/> &nbsp;</div>
                            </OverlayTrigger>
                    </NPIf>
                    <a href={`/orders/${this.props._id}`} style={this.props.style}>{this.props.value}</a>
                </div>
                <Dropdown style={{visibility:this.state.showHandler ? 'visible' : 'hidden'}}>
                    <Dropdown.Toggle variant="outline-info" id="dropdown-basic">
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item  onClick={() =>this.createRoute()}>Crear ruta </Dropdown.Item>
                        <Dropdown.Divider></Dropdown.Divider>
                        <Dropdown.Header>Agregar a ruta: </Dropdown.Header>
                        {
                            this.props.routes.map((route) => {
                                return (
                                    <Dropdown.Item  key={route._id} onClick={() =>this.addToRoute(route._id)}>
                                        {route.name}
                                    </Dropdown.Item>
                                )
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        )
    }
}
IdComponent.propTypes    = {
    routes: PropTypes.array,
    order: PropTypes.object,
    onRouteCreated: PropTypes.any
}
IdComponent.defaultProps = {
    routes: [],
    order:{},
    onRouteCreated: () => {}
}
const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" >
        Simple tooltip
    </Tooltip>
);
const getUser = (order) =>{
    if(order.user){
        return order.user;
    }else{
        return {
            name:order?.shipping?.name,
            lastName:order?.shipping?.lastName,
            email:order?.contact?.email
        }
    }
}
class OrdersTable extends React.Component {
    constructor(props) {
        super(props);

    }

    formatDate(date) {
        if (!date) {
            return '-';
        }
        return (
            <div>
                <div>{dayjs(date).format('DD MMM YYYY')}</div>
                <div>{dayjs(date).format('HH:mm:ss')}</div>
            </div>
        )
    }
    render() {
        const columns = [
            {
                dataField: '_id',
                text: 'Orden',
                formatter: (value, row) => {
                    const style = {
                        fontWeight: row.isViewedByUser ? 'normal' : 'bold'
                    }
                    return (
                        <IdComponent _id={row._id}
                                     order={row}
                                     style={style}
                                     value={value}
                                     onRouteAt={row.onRouteAt}
                                     routes={this.props.routes}
                                     onRouteCreated={this.props.onRouteCreated}
                        >
                        </IdComponent>
                    )
                },
                headerStyle: (row, rowIndex) => {
                    return {
                        width: '250px',
                        borderTop: 'none'
                    }
                },
                sort: true,
                sortCaret: (order) => {
                    return <SortBtns order={order}/>
                }
            },
            {
                dataField: 'created_at',
                text: 'Fecha',
                formatter: (value, row) => {
                    const style = {
                        fontWeight: row.isViewedByUser ? 'normal' : 'bold'
                    }
                    return <div style={style}>
                        {this.formatDate(value)}
                    </div>
                },
                headerStyle: () => {
                    return {
                        width: '130px',
                        borderTop: 'none'
                    }
                },
                sort: true,
                sortCaret: (order) => {
                    return <SortBtns order={order}/>
                }
            },
            {
                dataField: 'user',
                text: 'Cliente',
                formatter: (value, row) => {
                    const style = {
                        fontWeight: row.isViewedByUser ? 'normal' : 'bold'
                    }
                    const user = getUser(row);
                    return (<div style={style}>
                        <div>{user.name}</div>
                        <div>{user.last_name || user.lastName}</div>
                    </div>)



                },
                headerStyle: () => {
                    return {
                        borderTop: 'none'
                    }
                },
            },
            {
                dataField: 'requiresInvoice',
                text: '¿Requiere factura?',
                formatter: (value, row) => {
                    const txt   = value ? 'Sí' : 'No';
                    const style = value ? 'text-success font-weight-bold' : '';
                    return (
                        <span className={style}> {txt}</span>
                    )
                },
                sort: true,
                sortCaret: (order) => {
                    return <SortBtns order={order}/>
                },
                headerStyle: () => {
                    return {
                        width: '90px',
                        borderTop: 'none'
                    }
                },
            },
            {
                dataField: 'deliveryBlock',
                text: 'Fecha de entrega',
                headerStyle: () => {
                    return {
                        width: '200px',
                        borderTop: 'none'
                    }
                },
                formatter: (value) => {
                    if (value) {
                        return (
                            <div>
                                {value.dayLabel}
                                <br/>
                                {value.hoursLabel}
                            </div>

                        )
                    } else {
                        return ''
                    }
                },
            },
            {
                dataField: 'status',
                text: 'Estado de la orden',
                formatter: (value) => {
                    let variant = '';
                    let txt     = value;
                    switch (value) {
                        case 'processing':
                            variant = 'info';
                            txt     = 'Procesando';
                            break;
                        case 'received':
                            variant = 'info';
                            txt     = 'Recibida';
                            break;
                        case 'amount_validated':
                            variant = 'info';
                            txt     = 'Monto validado';
                            break;
                        case 'on_route':
                            variant = 'info';
                            txt     = 'En camino';
                            break;
                        case 'delivering':
                            variant = 'info';
                            txt     = 'Entregando';
                            break;
                        case 'delivered':
                            variant = 'info';
                            txt     = 'Entregado';
                            break;
                        case 'finished':
                            variant = 'info';
                            txt     = 'Terminado';
                            break;
                        case 'cancelled':
                            variant = 'danger';
                            txt     = 'Cancelada';
                            break;
                        default:
                            variant = 'info';
                            txt     = '---';
                            break;
                    }
                    return (
                        <Badge bg={variant}>{txt}</Badge>
                    )
                },
                headerStyle: () => {
                    return {
                        borderTop: 'none'
                    }
                },
            },
            {
                dataField: 'paymentMethod',
                text: 'Método de pago',
                formatter: (value) => {
                    switch (value) {
                        case 'card':
                            return 'Tarjeta de Crédito/Débito'
                        case 'cash':
                            return 'Efectivo'
                        case 'pos':
                            return 'Terminal Punto de Venta'
                        case 'mercado-pago':
                            return 'Mercado Pago'
                        default :
                            return '---'
                    }
                },
                headerStyle: () => {
                    return {
                        borderTop: 'none'
                    }
                },
            },
            {
                dataField: 'paymentStatus',
                text: 'Estado de pago',
                formatter: (value) => {
                    let variant = '';
                    let txt     = value;
                    switch (value) {
                        case 'paid':
                            variant = 'success';
                            txt     = 'Pagado';
                            break;
                        case 'pending':
                            variant = 'warning';
                            txt     = 'Pendiente de pago';
                            break;
                        default:
                            variant = 'danger';
                            break;
                    }
                    return (
                        <Badge bg={variant}>{txt}</Badge>
                    )
                },
                headerStyle: () => {
                    return {
                        borderTop: 'none'
                    }
                },
            },
            {
                dataField: 'total',
                text: 'Total',
                formatter: (value, row) => {
                    const style = {
                        fontWeight: row.isViewedByUser ? 'normal' : 'bold'
                    }
                    return (
                        <div style={style}>
                            {money(value)}
                        </div>
                    )
                },
                sort: true,
                sortCaret: (order) => {
                    return <SortBtns order={order}/>
                },
                headerStyle: () => {
                    return {
                        borderTop: 'none'
                    }
                },
            },
            {
                dataField: 'region',
                text: 'Región',
                formatter: (value, row) => {
                    if (!value) {
                        return null;
                    }
                    const style = {
                        backgroundColor: value.color,
                        color: 'white'
                    }
                    return (
                        <span className={'badge'} style={style} color={value.color}>{value.name}</span>
                    )
                },
                sort: true,
                sortCaret: (order) => {
                    return <SortBtns order={order}/>
                },
                headerStyle: () => {
                    return {
                        width: '90px',
                        borderTop: 'none'
                    }
                },
            },
        ];

        return (
            <>
                <NPIf condition={this.props.isLoading}>
                    <Spinner animation={'grow'} variant={'primary'}></Spinner>
                </NPIf>
                <NPIf condition={!this.props.isLoading}>
                    <BootstrapTable keyField='_id'
                                    data={this.props.orders}
                                    columns={columns}
                                    rowStyle={(row, rowIndex) => {
                                        return {
                                            backgroundColor: row.isViewedByUser ? '' : '#1f16462b'
                                        }
                                    }}
                                    noDataIndication={`No hay órdenes que mostrar`}
                                    bordered={false}
                                    classes={'table-overflow-auto'}

                    />
                    <NPPaginator numberOfPages={this.props.pages}
                                 onPageChanged={(p) => this.props.onPageChanged(p)}
                                 initialPage={this.props.currentPage}>

                    </NPPaginator>
                </NPIf>
            </>
        )
    }


}

export default withRouter(OrdersTable);

OrdersTable.propTypes    = {
    orders: PropTypes.array,
    isLoading: PropTypes.bool,
    pages: PropTypes.number,
    currentPage: PropTypes.number,
    onPageChanged: PropTypes.func,
    routes: PropTypes.array,
    onRouteCreated: PropTypes.func
}
OrdersTable.defaultProps = {
    orders     : [],
    isLoading  : true,
    pages      : 1,
    currentPage: 1,
    onPageChanged: () => {},
    routes: [],
    onRouteCreated: () => {}
}
